export default class {
    constructor($errors) {
        this.$errors = $errors;
    }

    has(key) {
        return this.$errors.hasOwnProperty(key);
    }

    first(key) {
        if (this.has(key) && Array.isArray(this.$errors[key])) return this.$errors[key][0];
    }

    all() {
        return this.$errors
    }
}
