import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { getAuthUser } from "@/util/globalFunction";
import { getEventId, getPropertyId } from "@/util/globalFunction";

const DEFAULT_PAGINATION_STATE = {
  current: 1,
  totalPerPage: 10,
  total: 0,
  last_page: 1,
};

export default {
  data() {
    const { query } = this.$route;
    const listQueryParams = {
      ...query,
      page: ( query.page && query.page > 0 ) ? parseInt(query.page) : 1,
      totalPerPage: ( query.totalPerPage && query.totalPerPage > 0 ) ? query.totalPerPage : DEFAULT_PAGINATION_STATE.totalPerPage,
    };

    const pagination = {
      current: listQueryParams.page,
      totalPerPage: listQueryParams.totalPerPage,
      total: 0,
      last_page: 0,
    };

    return {
      dataSource: [],
      pagination: pagination,
      search: ( ( query.search ) ? query.search : '' ),
      filters: '',
      formErrors: new Error({}),
      operation: ( query.operation ? query.operation : null ),
      listQueryParams: listQueryParams,
      listUrl: '',
      isFilterApplied: false,
      globalPropertyId: null,
      globalEventId: null,
      authUser: getAuthUser(),
      loading: true,
      companyProfiles: false,
      deleteId: null,
      checkFilterCount: false
    };
  },
  mounted() {
    this.globalEventId = getEventId() ? getEventId() : 0;
    this.globalPropertyId = getPropertyId() ? getPropertyId() : 0;
    this.loadList(this.listQueryParams);
  },
  methods: {
    redirectToRouteLink(link) {
      this.$router.push(link);
    },
    submitFilter() {
      this.checkFilterCountStatus();
    },
    clearFilter(key, value) {
      if ( key ) {
        this.filters[key] = value;
      }
    },
    checkFilterCountStatus() {
      this.checkFilterCount = _.filter(this.filters, (i, index) => ( index !== 'visible' && i !== null )).length;
      this.loadList(this.listQueryParams, true);
      return this.checkFilterCount;
    },
    async loadList(listQueryParams, applyFilter = false) {

      this.loading = true;
      console.log(this.loading)
      this.formErrors = new Error({});
      this.isFilterApplied = ( this.isFilterApplied === 'reset' ) ? false : applyFilter;

      const extraParams = this.getExtraParams();
      let newListQueryParams = ( listQueryParams ? listQueryParams : this.listQueryParams );
      const params = { ...newListQueryParams, ...extraParams };

      params.sortField = params.sortBy ? params.sortBy[0] : null;
      params.sortOrder = params.sortDesc ? params.sortDesc[0] ? 'desc' : 'asc' : null;
      try {
        const response = await request({
          method: "post",
          url: this.listUrl,
          data: params
        });

        const { data, meta } = response.data;
        this.dataSource = data;
        this.pagination = {
          ...this.pagination,
          current: parseInt(this.listQueryParams.page),
          total: parseInt(meta.total),
          last_page: parseInt(meta.last_page),
        };
        if ( !this.dataSource.length ) {
          this.loading = false;
        }
      } catch (error) {
        this.dataSource = [];
        console.error(error)
      }

      this.loading = false;

    },
    getExtraParams() {
      if ( this.$global.hasPermission("masterguestuser") || this.$global.hasPermission('subguestuser') ) {
        return {
          filters: {
            ...this.filters,
            event_id: this.globalEventId
          },
        };
      } else {
        return {
          filters: {
            ...this.filters,
            property_id: this.globalPropertyId,
            event_id: this.globalEventId
          },
        };
      }
    },
    handleOperationClose() {
      this.operation = null;
      this.formErrors = new Error({});
      const listQueryParams = Object.assign({}, this.$route.query);
      delete listQueryParams['operation'];
      delete listQueryParams['id'];
      this.listQueryParams = { ...this.listQueryParams, ...listQueryParams };
      this.$router.replace({ query: listQueryParams }).catch(() => {
      });
      this.afterCloseOperation();
    },
    handleSearch() {
      if ( this.search !== '' ) {
        this.listQueryParams.search = this.search;
        this.listQueryParams.page = 1;
      } else {
        this.listQueryParams.search = '';
      }

      this.loadList(this.listQueryParams)
    },
  },
  watch: {
    'pagination.current': function (newVal) {
      const currentPage = ( newVal ? newVal : 1 );
      const listQueryParams = {
        ...this.listQueryParams,
        page: parseInt(currentPage)
      };

      delete listQueryParams['operation'];
      delete listQueryParams['id'];
      this.listQueryParams = listQueryParams;

    },
    'pagination.totalPerPage': function (newVal) {
      const totalPerPage = ( newVal ? newVal : 100 );
      const listQueryParams = {
        ...this.listQueryParams,
        totalPerPage: parseInt(totalPerPage)
      };

      delete listQueryParams['operation'];
      delete listQueryParams['id'];
      this.listQueryParams = listQueryParams;
      // this.replaceQuery(listQueryParams)
    },
    filters: {
      handler: function (val, oldVal) {
        let submit = false;
        _.filter(val, (i, index) => {
          if ( i == "" || i == undefined ) {
            this.filters[index] = null;
          }
          if ( i !== "" || i !== undefined ) {
            submit = true;
          }
          if ( _.isArray(i) ) {
            if ( !i.length ) {
              this.filters[index] = null;
            }
            if ( i.length > 0 ) {
              submit = true;
            }
          }
        });

        if ( submit ) {
          this.submitFilter();
        }
      },
      deep: true,
    },
    listQueryParams: {
      handler: function () {
        this.loadList(this.listQueryParams);
      },
      deep: true,
    }
  }
};
