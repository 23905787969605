import Error from "@/core/services/Error";
import {request} from "@/core/services/Request";
import {getEventId} from "@/util/globalFunction";

const DEFAULT_FORM_STATE = {
    title: null,
    event_id: getEventId() ? getEventId() : null,
    _method: 'post',
};

const COLUMN_DEFINATION = () => [
    {
        text: "#",
        sortable: false,
        value: 'id',
    },
    {
        text: "Title",
        sortable: false,
        value: 'title',
    },
    {
        text: "Action",
        value: "action",
        width: 150,
    },

];

export default {
    data() {
        return {
            operationTitle: 'Groups',
            formFields: {...DEFAULT_FORM_STATE},
            formErrors: new Error({}),
            listUrl: '/sub/guest/users/groups',
            columns: COLUMN_DEFINATION(this),
        }
    },
    methods: {
        async handleSubmitOperation() {
            this.formErrors = new Error({})
            try {
                const response = await request({
                    url: this.formFields.id ? '/sub/guest/users/groups/update' : '/sub/guest/users/groups/create',
                    method: 'post',
                    data: this.formFields,
                })
                if (this.formFields.id) {
                    this.$global.itemUpdated()
                } else {
                    this.$global.itemAdded()
                }
                this.handleOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }
                this.$global.handleServerError(error)
            }

        },
        async handleDeleteOperation(id) {
            let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
                primaryBtnText: 'Yes',
                secondaryBtnText: 'No'
            });
            if (deleteResponse) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/sub/guest/users/groups/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.$global.itemDeleted()
                } catch (errors) {
                    this.$global.handleServerError(errors)
                }
            }
        },
        async handleEditOperation(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/sub/guest/users/groups/detail/${id}`,
                })
                const {data} = response.data
                this.formFields = {
                    ...data,
                    event_id: data.event ? data.event.id : null
                }
            } catch (e) {
                this.$global.itemEditFails()
                this.formFields = {...DEFAULT_FORM_STATE}
            }
        },
        handleOperationClose() {
            this.formFields = {...DEFAULT_FORM_STATE}
            this.$router.push({name: "groupList"})
        },
    },
}
